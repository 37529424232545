import format from "date-fns/fp/formatWithOptions";
import { de } from "date-fns/locale";

const formatToLocalDate = format({ locale: de }, "dd.MM.yyyy HH:mm");

export const UtcToLocale = ({ datetime }: { datetime?: string }): JSX.Element => {

  if (!datetime || !(new Date(datetime) instanceof Date)) {
    return <>-</>;
  }

  // check if datetime string already has trailing Z
  if (!datetime.endsWith("Z")) {
    datetime = datetime.concat("Z");
  }

  const localDate = formatToLocalDate(new Date(datetime));

  return <>{localDate}</>;
};
